import React, { FC } from "react";
import ContactForm from "../components/Shared/ContactUiComponents/ContactForm";
import { SEO } from "../components/seo";
import Layout from "../components/Layout/Layout";

type Props = {
  location: any;
};

const ContactPage: FC<Props> = ({ location }) => {
  return (
    <div>
      <Layout location={location.pathname}>
        <SEO
          title="Contact"
          description="Are you looking for a team that goes a step further? We are ready for all tasks."
          type="website"
        />
        <>
          <ContactForm topBanner writeUs />
        </>
      </Layout>
    </div>
  );
};

export default ContactPage;
